<template>
  <div>
    <q-form ref="editForm">
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable && isOld"
              label="삭제"
              icon="delete_forever"
              @btnClicked="deleteInfo" />
            <c-btn
              v-show="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="hygieneInspection"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <c-text
              :required="true"
              :editable="editable"
              label="위생점검명"
              name="hygieneInspectionName"
              v-model="hygieneInspection.hygieneInspectionName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <c-rest-facilities
              :required="true"
              :disabled="isOld"
              :editable="editable"
              label="휴게시설"
              name="sopRestFacilitiesId"
              v-model="hygieneInspection.sopRestFacilitiesId">
            </c-rest-facilities>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-datepicker
              :required="true"
              :disabled="isOld"
              :editable="editable"
              type="year"
              default="today"
              label="년도"
              name="inspectionYear"
              v-model="hygieneInspection.inspectionYear"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-field
              :editable="editable"
              label="점검자"
              name="checkerId"
              v-model="hygieneInspection.checkerId">
            </c-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-plant
              :editable="editable"
              type="edit"
              label="사업장"
              name="plantCd"
              v-model="hygieneInspection.plantCd">
            </c-plant>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="table"
      title="위생점검표"
      class="q-mt-sm"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="hygieneInspection.results"
      :gridHeight="gridHeight"
      :filtering="false"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :editable="editable"
    >
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hygieneInspectionDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopRestFacilitiesHygieneInspectionId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      hygieneInspection: {
        sopRestFacilitiesHygieneInspectionId: '',  // 휴게 시설 위생점검 일련번호
        plantCd: '',  // 사업장코드
        hygieneInspectionName: '',  // 위생점검명
        sopRestFacilitiesId: '',  // 휴게 시설 마스터 일련번호
        inspectionYear: '',  // 년도
        checkerId: '',  // 점검자
        stepCd: '',  // 진행단계
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        results: [], // 점검항목
      },
      grid: {
        merge: [
          { index: 0, colName: 'gubun' }
        ],
        columns: [
          {
            name: 'gubun',
            field: 'gubun',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'hygieneInspectionItemName',
            field: 'hygieneInspectionItemName',
            label: '위생점검 항목명',
            align: 'left',
            style: 'width:250px',
          },
          {
            name: 'month1',
            field: 'month1',
            label: '1월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month2',
            field: 'month2',
            label: '2월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month3',
            field: 'month3',
            label: '3월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month4',
            field: 'month4',
            label: '4월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month5',
            field: 'month5',
            label: '5월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month6',
            field: 'month6',
            label: '6월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month7',
            field: 'month7',
            label: '7월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month8',
            field: 'month8',
            label: '8월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month9',
            field: 'month9',
            label: '9월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month10',
            field: 'month10',
            label: '10월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month11',
            field: 'month11',
            label: '11월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
          {
            name: 'month12',
            field: 'month12',
            label: '12월',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
        ],
      },
      editable: true,
      isSave: false,
      detailUrl: '',
      itemListUrl: '',
      validUrl: '',
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopRestFacilitiesHygieneInspectionId);
    },
    gridHeight() {
      let height = this.contentHeight - 200;
      if (!height || height < 600) {
        height = 600
      }
      return String(height) + 'px';
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.rtf.his.hygieneInspection.get.url
      this.itemListUrl = selectConfig.sop.rtf.his.hygieneInspectionItem.list.url
      this.validUrl = selectConfig.sop.rtf.his.hygieneInspection.valid.url
      this.saveUrl = transactionConfig.sop.rtf.his.hygieneInspection.update.url
      this.deleteUrl = transactionConfig.sop.rtf.his.hygieneInspection.delete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopRestFacilitiesHygieneInspectionId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.hygieneInspection, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // 위생점검항목
        this.$set(this.hygieneInspection, 'checkerId', this.$store.getters.user.userId)
        this.$http.url = this.itemListUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.hygieneInspection.plantCd,
          useFlag: 'Y'
        };
        this.$http.request((_result) => {
          this.items = _result.data
          this.$set(this.hygieneInspection, 'results', this.$_.map(_result.data, item => {
            return {
              sopRestFacilitiesHygieneInspectionResultId: '',  // 휴게 시설 위생점검 항목결과 일련번호
              sopRestFacilitiesHygieneInspectionId: '',  // 휴게 시설 위생점검 일련번호
              sopRestFacilitiesHygieneInspectionItemId: item.sopRestFacilitiesHygieneInspectionItemId,  // 휴게 시설 위생점검 항목 일련번호
              gubun: item.gubun,  // 구분
              hygieneInspectionItemName: item.hygieneInspectionItemName,  // 휴게 시설 위생점검 항목명
              month1: null,  // 1월 점검결과
              month2: null,  // 2월 점검결과
              month3: null,  // 3월 점검결과
              month4: null,  // 4월 점검결과
              month5: null,  // 5월 점검결과
              month6: null,  // 6월 점검결과
              month7: null,  // 7월 점검결과
              month8: null,  // 8월 점검결과
              month9: null,  // 9월 점검결과
              month10: null,  // 10월 점검결과
              month11: null,  // 11월 점검결과
              month12: null,  // 12월 점검결과
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C'
            }
          }))
        },);
      }
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopRestFacilitiesHygieneInspectionId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });

    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.rtf.his.hygieneInspection.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.rtf.his.hygieneInspection.insert.url
        this.mappingType = 'POST';
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.validUrl;
          this.$http.type = 'GET';
          this.$http.param = {
            inspectionYear: this.hygieneInspection.inspectionYear,
            sopRestFacilitiesId: this.hygieneInspection.sopRestFacilitiesId,
            sopRestFacilitiesHygieneInspectionId: this.hygieneInspection.sopRestFacilitiesHygieneInspectionId
          };
          this.$http.request((_result) => {
            if (_result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.hygieneInspection.regUserId = this.$store.getters.user.userId;
                  this.hygieneInspection.chgUserId = this.$store.getters.user.userId;

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message: '입력하신 년도, 휴게시설의 위생점검표가 이미 존재합니다.\n다시 입력바랍니다.',
                type: 'warning', // success / info / warning / error
              });
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopRestFacilitiesHygieneInspectionId', result.data)
      this.getDetail();
    },
  }
};
</script>